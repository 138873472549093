<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import store from './store';
import { titleStore } from './store/store.js';

export default ({
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  mounted() {
    if (!localStorage.getItem('theme')) {
      store.dispatch('themeSet', "dark");
      document.querySelector("html").classList.add("dark");
      document.querySelector("#app").classList.add("darkMode");
    } else if(localStorage.getItem('theme') == "dark") {
      document.querySelector("html").classList.add("dark");
      document.querySelector("#app").classList.add("darkMode");
    }

    // We ask for the titles here, to make sure they are available everywhere
    titleStore.getMainTitle(this.lang);
    titleStore.getSubtitles(this.lang);
  },
})
</script>

<style>
  #app {
        min-height: 100vh;
        position: relative;
        -webkit-tap-highlight-color: transparent;
  }
  #app.darkMode:after{
    display: block;
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgb(36, 36, 36);
  }
  ::-webkit-scrollbar {
      display: none;
  }
</style>