import { createRouter, createWebHistory } from "vue-router";
import store from '../store'
import multiguard from 'vue-router-multiguard'

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}
function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371;

  var dLat = degreesToRadians(lat2-lat1);
  var dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return earthRadiusKm * c;
}

const locationCheck = function (to, from, next) {
  getLocationPromise()
    .then((res) => {
      const latitude  = res.coords.latitude;
      const longitude = res.coords.longitude;
      // TODO need to change museum location
      const museum_latitude = res.coords.latitude;
      const museum_longitude = res.coords.longitude;
      const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude, longitude);
      if (distance > 0.07) {
        next('/access-denied');
      } else {
        next();
      }
    }).catch((error) => {
      // console.log(error)
      next('/access-denied');
    });
}

let getLocationPromise = () => {
  return new Promise(function (resolve, reject) {
      // Promisifying the geolocation API
      navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
      );
  });
};

const pinExist = function (to, from, next) {
  try{
    const result = store.dispatch('pinCheck');
    result.then(res => {
      if (res && res != '') {
        next();
      } else {
        next('/access-denied');
      }
    }).catch(() => {
      next('/access-denied');
    })
  }catch(e){
      // console.log(e)
      next('/access-denied');
  }
}

const pinOrTimeline = function (to, from, next) {
  try{
    const pinCheckResult = store.dispatch('pinCheck');
    pinCheckResult.then(res => {
      if (res && res != '') {
        next();
      } else {
        const timelineCheckResult = store.dispatch('timelineCheck', to.params.id);
        timelineCheckResult.then(timeline_res => {
          if (timeline_res.in) {
            next();
          } else {
            next('/access-denied');
          }
        }).catch(() => {
          next('/access-denied');
        });
      }
    }).catch(() => {
      next('/access-denied');
    })
  }catch(e){
      // console.log(e)
      next('/access-denied');
  }
}

const pinOrTimelineOrLocation = function (to, from, next ) {
  try{
    const pinCheckResult = store.dispatch('pinCheck');
    pinCheckResult.then(res => {
      if (res && res != '') {
        next();
      } else {
        const timelineCheckResult = store.dispatch('timelineCheck', to.params.id);
        timelineCheckResult.then(timeline_res => {
          if (timeline_res.in) {
            next();
          } else {
            getLocationPromise().then((res) => {
              const latitude  = res.coords.latitude;
              const longitude = res.coords.longitude;
              // TODO need to change museum location
              const museum_latitude = res.coords.latitude;
              const museum_longitude = res.coords.longitude;
              const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
              if (distance <= 0.07)  {
                next();
              } else {
                next('/access-denied');
              }
            }).catch(() => {
              // console.log(error)
              next('/access-denied');
            });
          }
        }).catch(() => {
          getLocationPromise().then((res) => {
            const latitude  = res.coords.latitude;
            const longitude = res.coords.longitude;
            // TODO need to change museum location
            const museum_latitude = res.coords.latitude;
            const museum_longitude = res.coords.longitude;
            const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
            if (distance <= 0.07) {
              next();
            } else {
              next('/access-denied');
            }
          }).catch(() => {
            // console.log(error)
            next('/access-denied');
          });
        })
      }
    }).catch(() => {
      getLocationPromise().then((res) => {
        const latitude  = res.coords.latitude;
        const longitude = res.coords.longitude;
        // TODO need to change museum location
        const museum_latitude = res.coords.latitude;
        const museum_longitude = res.coords.longitude;
        const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
        if (distance <= 0.07) {
          next();
        } else {
          next('/access-denied');
        }
      }).catch(() => {
        // console.log(error)
        next('/access-denied');
      });
    });

  }catch(e){
      // console.log(e)
      next('/access-denied');
  }
}

const pinOrLocation = function (to, from, next ) {
  try{
    const pinCheckResult = store.dispatch('pinCheck');
    pinCheckResult.then(res => {
      if (res && res != '') {
        next();
      } else {
        getLocationPromise().then((res) => {
          console.log
          const latitude  = res.coords.latitude;
          const longitude = res.coords.longitude;
          // TODO need to change museum location
          const museum_latitude = res.coords.latitude;
          const museum_longitude = res.coords.longitude;
          const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
          if (distance <= 0.07)  {
            next();
          }
        }).catch(() => {
          // console.log(error)
          next('/access-denied');
        });
      }
    }).catch(() => {
      getLocationPromise().then((res) => {
        const latitude  = res.coords.latitude;
        const longitude = res.coords.longitude;
        // TODO need to change museum location
        const museum_latitude = res.coords.latitude;
        const museum_longitude = res.coords.longitude;
        const distance = distanceInKmBetweenEarthCoordinates(museum_latitude, museum_longitude, latitude+0.000624, longitude);
        if (distance <= 0.07) {
          next();
        } else {
          next('/access-denied');
        }
      }).catch(() => {
        // console.log(error)
        next('/access-denied');
      });
    });

  }catch(e){
      // console.log(e)
      next('/access-denied');
  }
}

const notFound = function (to, from, next) {
  try{
    const publishedCheckResult = store.dispatch('publishedChec', to.params.id);
    publishedCheckResult.then(res => {
      if (res.notfound) {
        next('/');
      } else {
        next();
      }
    }).catch(() => {
      next('/');
    })
  }catch(e){
    // console.log(e)
    next('/');
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "tv" */ "../views/mures/TV.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/mures/Home.vue" ),
  },
  {
    path: "/tv/:id",
    name: "TVDetail",
    beforeEnter: multiguard([pinOrTimelineOrLocation]),
    component: () =>
      import( /* webpackChunkName: "tv detail" */ "../views/TVDetail.vue"),
  },
  {
    path: "/map",
    name: "Map",
    component: () =>
      import( /* webpackChunkName: "map" */ "../views/mures/MapView.vue"),
  },
  {
    path: "/instruments",
    name: "Instruments",
    component: () =>
      import( /* webpackChunkName: "instruments" */ "../views/mures/Instruments.vue"),
  },
  {
    path: "/pictures/:id",
    name: "Pictures",
    component: () =>
      import( /* webpackChunkName: "pictures" */ "../views/mures/Pictures.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundPage",
    component: () =>
      import( /* webpackChunkName: "404 page" */ "../views/NotFoundPage.vue"),
  },
    // {
  //   path: "/tv/langSelect",
  //   name: "LanguageTV",
  //   component: () =>
  //     import( /* webpackChunkName: "tv language" */ "../views/LanguageTV.vue"),
  // },
    // {
  //   path: "/tv/:id/timeline",
  //   name: "TVPin",
  //   // beforeEnter: multiguard([notFound, locationCheck]),
  //   beforeEnter: multiguard([notFound]),
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/EntityPin.vue"),
  // },
    // {
  //   path: "/access-denied",
  //   name: "AccessDenied",
  //   component: () =>
  //     import( /* webpackChunkName: "3d" */ "../views/AccessDenied.vue"),
  // },
    // {
  //   path: "/tv/:id/game",
  //   name: "TVGameDetail",
  //   beforeEnter: multiguard([pinOrTimelineOrLocation]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet detail" */ "../views/TVGameDetail.vue"),
  // },
  // {
  //   path: "/design-system",
  //   name: "Design System",
  //   component: () =>
  //     import( /* webpackChunkName: "design-system" */ "../views/DesignSystem.vue"),
  // },
  // {
  //   path: "/tablets",
  //   name: "Tablet",
  //   // beforeEnter: multiguard([locationCheck]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet" */ "../views/Tablet.vue"),
  // },
  // {
  //   path: "/mobiles",
  //   name: "Mobile",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   beforeEnter: multiguard([pinExist]),
  //   component: () =>
  //     import( /* webpackChunkName: "mobile" */ "../views/Mobile.vue"),
  // },
  // {
  //   path: "/quick-login",
  //   name: "Quick Login",
  //   // beforeEnter: multiguard([locationCheck]),
  //   component: () =>
  //     import( /* webpackChunkName: "quick-login" */ "../views/QuickLogin.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import( /* webpackChunkName: "login" */ "../views/Login.vue"),
  // },
  // {
  //   path: "/starterscreenMobile",
  //   name: "HomeMobileMuzeum",
  //   component: () =>
  //     import( /* webpackChunkName: "HomeMobileMuzeum" */ "../views/HomeMobileMuzeum.vue"),
  // },
  // {
  //   path: "/thank-you",
  //   name: "ThankYou",
  //   // beforeEnter: multiguard([pinExist]),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "thank-you" */ "../views/ThankYou.vue"),
  // },
  // {
  //   path: "/tablet/:id",
  //   name: "TabletDetail",
  //   beforeEnter: multiguard([pinOrTimelineOrLocation]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet detail" */ "../views/TabletDetail.vue"),
  // },
  // {
  //   path: "/mobile/:id",
  //   name: "MobileDetail",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   beforeEnter: multiguard([pinOrTimelineOrLocation]),
  //   component: () =>
  //     import( /* webpackChunkName: "mobile detail" */ "../views/MobileDetail.vue"),
  // },
  // {
  //   path: "/mobile/menu",
  //   name: "MobileMenu",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   // beforeEnter: multiguard([pinExist]),
  //   component: () =>
  //     import( /* webpackChunkName: "mobile menu" */ "../views/MobileMenu.vue"),
  // },
  // {
  //   path: "/mobile/langSelect",
  //   name: "LanguageMobile",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   // beforeEnter: multiguard([pinExist]),
  //   component: () =>
  //     import( /* webpackChunkName: "mobile language" */ "../views/LanguageMobile.vue"),
  // },
  // {
  //   path: "/mobile/qrCode",
  //   name: "MobileQRCodeReader",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   beforeEnter: multiguard([pinOrLocation]),
  //   component: () =>
  //     import( /* webpackChunkName: "mobile language" */ "../views/MobileQRCodeReader.vue"),
  // },
  // {
  //   path: "/tablet/:id/timeline",
  //   // beforeEnter: multiguard([notFound, locationCheck]),
  //   beforeEnter: multiguard([notFound]),
  //   name: "TabletPin",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/EntityPin.vue"),
  // },
  // {
  //   path: "/onboarding/1",
  //   name: "Onboarding1",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard1.vue"),
  // },
  // {
  //   path: "/onboarding/2",
  //   name: "Onboarding2",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard2.vue"),
  // },
  // {
  //   path: "/onboarding/3",
  //   name: "Onboarding3",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard3.vue"),
  // },
  // {
  //   path: "/onboarding/4",
  //   name: "Onboarding4",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/onboarding/Onboard4.vue"),
  // },
  // {
  //   path: "/onboarding/end",
  //   name: "OnboardingEnd",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/onboarding/OnboardEnd.vue"),
  // },
  // {
  //   path: "/timelineedit",
  //   name: "timelineedit",
  //   // beforeEnter: multiguard([pinExist]),
  //   // meta: {
  //   //   requiresAuth: true
  //   // },
  //   component: () =>
  //     import( /* webpackChunkName: "timelinetv" */ "../views/TimeLineEdit.vue"),
  // },
  // {
  //   path: "/password-reset",
  //   name: "PasswordReset",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue"),
  // },
  // {
  //   path: "/pin-check",
  //   name: "timelinepin",
  //   component: () =>
  //     import( /* webpackChunkName: "timelinepin" */ "../views/TimeLinePin.vue"),
  // },
  // {
  //   path: "/timeline/:id",
  //   name: "TimeLineDetail",
  //   component: () =>
  //     import( /* webpackChunkName: "TimelineDetail" */ "../views/TimeLineDetail.vue"),
  // },
  // {
  //   path: "/welcome-screen",
  //   name: "WelcomeScreen",
  //   component: () =>
  //     import( /* webpackChunkName: "welcome-screen" */ "../views/WelcomeScreen.vue"),
  // },
  // {
  //   path: "/tablet-register",
  //   name: "TabletRegister",
  //   component: () =>
  //     import( /* webpackChunkName: "TabletRegister" */ "../views/TabletRegister.vue"),
  // },
  // {
  //   path: "/tablet-thank-you",
  //   name: "TabletThankYou",
  //   component: () =>
  //     import( /* webpackChunkName: "TabletThankYou" */ "../views/TabletThankYou.vue"),
  // },
  // {
  //   path: "/tablet-onboarding",
  //   name: "TabletOnBoarding",
  //   component: () =>
  //     import( /* webpackChunkName: "TabletOnBoarding" */ "../views/TabletOnBoarding.vue"),
  // },
  // {
  //   path: "/ar-scanner",
  //   name: "DDD",
  //   component: () =>
  //     import( /* webpackChunkName: "3d" */ "../views/ddd.vue"),
  // },
  // {
  //   path: "/timelines",
  //   name: "TimeLineList",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "TimeLineList" */ "../views/TimeLineList.vue"),
  // },
  // {
  //   path: "/selfie",
  //   name: "Selfie",
  //   meta: {
  //     // requiresAuth: true
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "Selfie" */ "../views/Selfie.vue"),
  // },
  // {
  //   path: "/selfie-gallery",
  //   name: "SelfieGallery",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "Selfie" */ "../views/SelfieGallery.vue"),
  // },
  // {
  //   path: "/selfie-instruction/1",
  //   name: "SelfieInstruction1",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/instructions/selfie/SelfieInstruction1.vue"),
  // },
  // {
  //   path: "/selfie-instruction/2",
  //   name: "SelfieInstruction2",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/instructions/selfie/SelfieInstruction2.vue"),
  // },
  // {
  //   path: "/selfie-instruction/3",
  //   name: "SelfieInstruction3",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/instructions/selfie/SelfieInstruction3.vue"),
  // },
  // {
  //   path: "/selfie-instruction/end",
  //   name: "SelfieInstructionEnd",
  //   component: () =>
  //     import( /* webpackChunkName: "pin" */ "../views/instructions/selfie/SelfieInstructionEnd.vue"),
  // },
  // {
  //   path: "/tablet/:id/game",
  //   name: "TabletGameDetail",
  //   beforeEnter: multiguard([pinOrTimelineOrLocation]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet detail" */ "../views/TabletGameDetail.vue"),
  // },
  // {
  //   path: "/bird_sounds",
  //   name: "BirdSound",
  //   // beforeEnter: multiguard([locationCheck]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet" */ "../views/BirdSound.vue"),
  // },
  // {
  //   path: "/bird_sounds_tutorial",
  //   name: "BirdSoundTutorial",
  //   // beforeEnter: multiguard([locationCheck]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet" */ "../views/BirdSoundTutorial.vue"),
  // },
  // {
  //   path: "/bird_sounds_start",
  //   name: "BirdSoundStart",
  //   // beforeEnter: multiguard([locationCheck]),
  //   component: () =>
  //     import( /* webpackChunkName: "tablet" */ "../views/BirdSoundStart.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
});

/** * ROUTE GUARD FOR PROTECTED ROUTES ***/
router.beforeEach((to, from, next) => {
  // See if auth is required at all
  const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  // console.log(store.state);
  // console.log(authUser.data.user.id);

  if (to.meta.requiresAuth) {
    // If so get user info from token
    // Check auth and see if user has the right permissions
    if (!authUser) {
      if (to.path !== '/') {
        store.state.redirectUrl = to.path
      }
      next('/access-denied')
    } else {
      next()
    }
  }
  if (to.meta.requiresNotAuth) {
    if (authUser) {
      next('/')
    } else {
      next()
    }
  }

  next()
})

export default router;
