import axios from 'axios';
import { reactive } from 'vue'

const baseURL = process.env.VUE_APP_AXIOS_URL;

export const titleStore = reactive({
  exhibitionTitle: '',
  exhibitionSubtitles: [],

  // The main title of the exhibition is the site title of the WP site
  getMainTitle(lang) {
    axios.get(baseURL + `/wp-json/wp/v2/main_title`, {params: {lang}}, {
      headers: {
              'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
        this.exhibitionTitle = response.data;
    }).catch( (error) => {
        console.log(error);
    }); 
  },
  
  // The subtitles of the exhibition are queried separately from the WP site title
  getSubtitles(lang) {
    axios.get(baseURL + `/wp-json/wp/v2/home_titles`, {params: {lang}}, {
      headers: {
              'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
        this.exhibitionSubtitles = response.data;
    }).catch( (error) => {
        console.log(error);
    });
  },
});

export const languageStore = reactive({
  language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',

  setLanguage(lang) {
    this.language = lang;
    localStorage.setItem('language', lang);
  },
});